import React, { Suspense, useState } from 'react';

import Toolbars from './Toolbars';
import Table from './Table';
import TableSkeleton from 'components/Skeletons/TableSkeleton';

const Cards = () => {
  const [ panelState, setPanelState ] = useState(false);
  const [ action, setAction ] = useState('');
  const openPanel = (action = '') => {
    setPanelState(true);
    setAction(action);
  }
  const closePanel = () => {
    setPanelState(false);
    setAction('');
  }

  return (
    <div className="content">
      <Toolbars
        panelState={panelState}
        openPanel={openPanel}
        closePanel={closePanel}
        action={action} />
      <Suspense fallback={<TableSkeleton />}>
        <Table openPanel={openPanel} />
      </Suspense>
    </div>
  );
};

export default Cards;
