import React, { useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";

import axios from '../../utils/axios';
import { currentCardSelector } from "recoil/Card";
import { cardsSelector } from "recoil/Card";
import { currentCardID } from "recoil/Card";

function CardForm(props) {
  const { closePanel, action } = props;
  const selectedCard = useRecoilValue(currentCardSelector);
  const isEditMode = selectedCard ? true : false;
  const resetCards = useResetRecoilState(cardsSelector);
  const setCurrentCardID = useSetRecoilState(currentCardID);

  const validationSchema = yup.object({
//    prfFullname: yup.string().required("Card Name is required"),
      prfPassword: yup.string().concat(!isEditMode ? yup.string().required('Password is required') : null),
      confPassword: yup.string().oneOf([yup.ref('prfPassword'), null], 'Password confirmation must match'),
  })

  const initialValues = {
    prfName: '',
    prfFullname: '',
    prfSlug: '',
    prfImage: '',
    prfEmail: '',
    prfPassword: '',
    prfRole: 1,
    prfQuote: '',
    confPassword:''
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const slug = values.prfFullname.split(' ').join('.')
      console.log("<><><>",slug)
      const data = {
        prfName: values.prfName,
        prfFullname: values.prfFullname,
        prfSlug: slug,
        prfEmail: values.prfEmail,
        prfPassword: values.prfPassword,
        prfImage: values.prfImage,
      }

//      data.prfSlug = data.prfFullname.split(' ').join('.')
      console.log(data.prfSlug)
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }

      try {
        const conf = { headers: { 'Content-Type': 'multipart/form-data' } }
        let message = '';
        if (isEditMode) {
          const res = await axios.put(`/api/cards/${selectedCard.prfID}`, formData, conf);
          message = res.data.message;
        } else {
          const res = await axios.post('/api/cards', formData, conf);
          message = res.data.message;
        }
        resetCards();
        handleCancel();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        const response = error.error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          data.map((err) => formik.setFieldError(err.param, err.msg));
        }
      }
    },
  });

  useEffect(() => {
    if (isEditMode) {
      const fields = ['prfName', 'prfFullname', 'prfSlug', 'prfEmail', 'prfPassword'];
      fields.map((field) => formik.setFieldValue(field, selectedCard[field], false))
    }
  }, [selectedCard])

  const handleCancel = () => {
    closePanel();
    formik.resetForm();
    setCurrentCardID(0);
  }

  return (
    <>
      <Row>
        <Col md="12 mt-3">
          <Form onSubmit={formik.handleSubmit} className="form-horizontal" method="post">
            <Row hidden>
              <Label sm="2">Photo</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="file"
                    name="prfImage"
                    readOnly={action === 'READ'}
                    onChange={(e) => formik.setFieldValue("prfImage", e.currentTarget.files[0])} />
                </FormGroup>
                {formik.touched.prfImage && Boolean(formik.errors.prfImage) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfImage}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Full Name</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="prfFullname"
                    readOnly={action === 'READ'}
                    value={formik.values.prfFullname}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.prfFullname && Boolean(formik.errors.prfFullname) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfFullname}</label>}
              </Col>
            </Row>
            
            <Row>
              <Label sm="2">Email</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="prfEmail"
                    readOnly={action === 'READ'}
                    value={formik.values.prfEmail}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.prfEmail && Boolean(formik.errors.prfEmail) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfEmail}</label>}
              </Col>
            </Row>
            <Row hidden={action === 'READ' ? true : false}>
              <Label sm="2">Password</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="password"
                    name="prfPassword"
                    readOnly={action === 'READ'}
                    value={formik.values.prfPassword}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.prfPassword && Boolean(formik.errors.prfPassword) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfPassword}</label>}
              </Col>
            </Row>
            <Row hidden={action === 'READ' ? true : false}>
              <Label sm="2">Password Confirmation</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="password"
                    name="confPassword"
                    readOnly={action === 'READ'}
                    value={formik.values.confPassword}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.confPassword && Boolean(formik.errors.confPassword) && <label className="mt-0 mb-3 text-danger">{formik.errors.confPassword}</label>}
              </Col>
            </Row>
            {/** 
            <Row>
              <Label sm="2">Serial ID</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="crdSerialID"
                    readOnly={action === 'READ'}
                    value={formik.values.crdSerialID}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.crdSerialID && Boolean(formik.errors.crdSerialID) && <label className="mt-0 mb-3 text-danger">{formik.errors.crdSerialID}</label>}
              </Col>
            </Row>
            */}
            <Row>
              <Label sm="2">Phone</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="prfName"
                    readOnly={action === 'READ'}
                    value={formik.values.prfName}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.prfName && Boolean(formik.errors.prfName) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfName}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Slug</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="prfSlug"
                    readOnly={action === 'READ'}
                    value={formik.values.prfSlug}
                    onChange={formik.handleChange} 
                    disabled/>
                </FormGroup>
                {/** formik.touched.prfSlug && Boolean(formik.errors.prfSlug) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfSlug}</label>*/}
              </Col>
            </Row>
            <Row>
              <Label sm="2" />
              <Col sm="10" className="text-right">
                <FormGroup>
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    {action === 'READ' ? 'Close' : 'Cancel'}
                  </Button>
                  {action !== 'READ' &&
                    <Button
                      type="submit"
                      color="primary">
                      Save
                    </Button>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default CardForm;
