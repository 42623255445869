function errorResponseHandler(error) {
    if (error) {
        let message;
        if (error.response) {
            if (error.response.status === 500) message = "Something went wrong";
            if (error.response.status === 401) message = "You don't have access to this page";
            else message = error.response.data.message;
            return Promise.reject({
                is_axios: true,
                invalidate : false,
                error: error,
                status: error.response.status,
                data : error.response.data.errors,
                message: message + "asd"
            });
        }
    }
}

export default errorResponseHandler;