import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TableSkeleton = () => (
    <>
        <Skeleton height="3rem" style={{ marginBottom: "10px" }} />
        <Skeleton count={5} />
    </>
)

export default TableSkeleton
