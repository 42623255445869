import Dashboard from "views/Dashboard.js";
import Cards from "views/cards";
import Login from "views/Pages/Login";
import UserCards from "views/usercards";
const routes = [
  {
    path: "/login",
    component: Login,
    hide: true,
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Master Data",
    icon: "fa fa-database",
    state: "mapsCollapse",
    views: [
      {
        path: "/cards",
        name: "Profiles",
        icon: "fa fa-id-card",
        component: Cards,
        layout: "/admin",
      },
      {
        path: "/user-cards",
        name: "User Cards",
        icon: "fa fa-id-card",
        component: UserCards,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
