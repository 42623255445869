import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { authUserState } from "recoil/Auth";
import axios from '../../utils/axios';
import { currentCardSelector } from "recoil/Card";
import { cardsSelector } from "recoil/Card";
import { currentCardID } from "recoil/Card";

const ChangeSocial =({isOpen, onToggle, isRefresh, onRefresh, card}) => {
  //const { isPhoto, toggle } = props;
  const history = useHistory();
  const resetCards = useResetRecoilState(cardsSelector);
  const setCurrentCardID = useSetRecoilState(currentCardID);
  const authUser = useRecoilValue(authUserState);
  const validationSchema = yup.object({
//    prfFullname: yup.string().required("Card Name is required"),
  })
console.log("Selected Card =>>>>>",card);
  const initialValues = {
    prfName: '',
    prfFullname: '',
    prfSlug: '',
    prfImage: '',
    prfEmail: '',
    prfPassword: '',
    prfRole: 1,
    prfLinkedin: '',
    prfFacebook: '',
    prfTwitter: '',
    prfInstagram: ''
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const slug = values.prfFullname.split(' ').join('.')
      const data = {
        prfName: values.prfName,
        prfFullname: values.prfFullname,
        prfSlug: slug,
        prfEmail: values.prfEmail,
        prfPassword: values.prfPassword,
        prfImage: values.prfImage,
        prfLinkedin: values.prfLinkedin,
        prfFacebook: values.prfFacebook,
        prfTwitter: values.prfTwitter,
        prfInstagram: values.prfInstagram
      }

      data.prfSlug = data.prfFullname.split(' ').join('.')
      //console.log(data.prfSlug)
      //console.log(data.prfEmail)
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }

      try {
        console.log("SUBMIT =>>>>>>>>>>", data)
        const conf = { headers: { 'Content-Type': 'multipart/form-data' } }
        let message = '';
       // if (isEditMode) {
          const res = await axios.put(`/api/cards/${authUser.prfID}/social`, formData, conf);
          message = res.data.message;
       // } else {
       //   const res = await axios.post('/api/cards', formData, conf);
       //   message = res.data.message;
       // }
       console.log("SUBMIT =>>>>>>>>>>", res)
        resetCards();
        handleCancel();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        });

        history.push(`/profile/${data.prfSlug}`);
      } catch (error) {
        const response = error.error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          data.map((err) => formik.setFieldError(err.param, err.msg));
        }
      }
    },
  });

  useEffect(() => {
      console.log(card);
      const fields = ['prfName', 'prfFullname', 'prfSlug', 'prfEmail', 'prfPassword'];
      fields.map((field) => formik.setFieldValue(field, card[field], false))
  }, [authUser])

  const handleCancel = () => {
    //closePanel();
    //formik.resetForm();
    //setCurrentCardID(0);
    
  }

  return (

    <Modal isOpen={isOpen} onToggle={onToggle}>
        <ModalHeader>
          Social Media
        </ModalHeader>
          <Form onSubmit={formik.handleSubmit} className="form-horizontal" method="post">
            <ModalBody>
                      
                <FormGroup hidden>
                  <Input
                    type="file"
                    name="prfImage"
                    //readOnly={action === 'READ'}
                    onChange={(e) => formik.setFieldValue("prfImage", e.currentTarget.files[0])} />
                </FormGroup>
                {formik.touched.prfImage && Boolean(formik.errors.prfImage) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfImage}</label>}
                  <FormGroup hidden>
                    <Input
                      type="text"
                      name="prfFullname"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfFullname}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  {formik.touched.prfFullname && Boolean(formik.errors.prfFullname) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfFullname}</label>}
                  <FormGroup hidden>
                    <Input
                      type="text"
                      name="prfEmail"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfEmail}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  {formik.touched.prfEmail && Boolean(formik.errors.prfEmail) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfEmail}</label>}
                
                  <FormGroup hidden>
                    <Input
                      type="text"
                      name="prfPassword"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfPassword}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  {formik.touched.prfPassword && Boolean(formik.errors.prfPassword) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfPassword}</label>}
                  <FormGroup hidden>
                    <Label>Hanphone</Label>
                    <Input
                      type="text"
                      name="prfName"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfName}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                        <img
                            alt="..."
                            src={require("assets/img/linkedin.png").default}
                            style={{width: 25, height: 25}}
                          />
                          &nbsp; https://www.linkedin.com/ :
                          
                    </Label>
                    <Input
                      type="text"
                      name="prfLinkedin"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfLinkedin}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                    <img
                            alt="..."
                            src={require("assets/img/facebook.png").default}
                            style={{width: 25, height: 25}}
                          />
                          &nbsp; https://www.facebook.com/ :
                      </Label>
                    <Input
                      type="text"
                      name="prfFacebook"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfFacebook}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                    <img
                            alt="..."
                            src={require("assets/img/twitter.png").default}
                            style={{width: 25, height: 25}}
                          />
                          &nbsp; Twitter @ :
                    </Label>
                    <Input
                      type="text"
                      name="prfTwitter"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfTwitter}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  <FormGroup>
                  <Label>
                    <img
                            alt="..."
                            src={require("assets/img/instagram.png").default}
                            style={{width: 25, height: 25}}
                          />
                          &nbsp; Instagram @ :
                    </Label>
                    <Input
                      type="text"
                      name="prfInstagram"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfInstagram}
                      onChange={formik.handleChange} />
                  </FormGroup>
                  {formik.touched.prfName && Boolean(formik.errors.prfName) && <label className="mt-0 mb-3 text-danger">{formik.errors.prfName}</label>}
                  <FormGroup hidden>
                    <Input
                      type="text"
                      name="prfSlug"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfSlug}
                      onChange={formik.handleChange} 
                      disabled/>
                  </FormGroup>
                  <FormGroup hidden>
                    <Input
                      type="text"
                      name="prfQuote"
                      //readOnly={action === 'READ'}
                      value={formik.values.prfQuote}
                      onChange={formik.handleChange} 
                      disabled/>
                  </FormGroup>
            </ModalBody>
            <ModalFooter>
                  <FormGroup>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={onToggle}
                        >
                        cancel
                      </Button>

                      <Button
                        onClick={onRefresh}
                        type="submit"
                        color="primary">
                        Save
                      </Button>
                    
                  </FormGroup>
            </ModalFooter>
          </Form>
        
    </Modal>
      
  
  );
}

export default ChangeSocial;
