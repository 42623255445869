import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";
import { Link, useHistory, Redirect } from "react-router-dom";
import axios from '../utils/axios';
import { authUserState } from "recoil/Auth";
import BackgroundProfile from '../assets/img/bg/damir-bosnjak.jpg'
import PhotoForm from "./Profiles/PhotoForm";
import { useRecoilValue } from "recoil";
import { useSetRecoilState } from "recoil";
import { logout } from "../utils/axios";
import ChangePassword from "./Profiles/ChangePassword";
import {
    Card,
    CardBody,
    CardFooter,
    Row,
    Col,
    CardHeader,
    CardTitle,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
    Modal
} from "reactstrap";
// core components
import {
    chartExample10,
} from "variables/charts.js";
import ChangeName from "./Profiles/ChangeName";
import ChangeEmail from "./Profiles/ChangeEmail";
import ChangeSocial from "./Profiles/ChangeSocial";
import ChangePage from "./Profiles/ChangePage";

const Template01 = () => {
    const { slug } = useParams();
    const { crdID } = useParams();
    const [card, setCard] = useState();
    const history = useHistory();

    const authUser = useRecoilValue(authUserState);
    const [isRefresh, setIsRefresh] = useState(true);
    const [isPhoto, setIsPhoto] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isEmail, setEmail] = useState(false);
    const [isPassword, setPassword] = useState(false);
    const [isForm, setForm] = useState(false);
    const [isSocial, setSocial] = useState(false);



    const toggle = () => {
        setIsPhoto(!isPhoto);
    };
    
    const toggleName = () => {
        setIsName(!isName);
    };

    const togglePassword = () => {
        setPassword(!isPassword);
    };

    const toggleEmail = () => {
        setEmail(!isEmail);
    };
    
    const toggleForm = () => {
        setForm(!isForm);
    };

    const toggleSocial = () => {
        setSocial(!isSocial);
    };
   

    const onRefresh = () => {
        setIsRefresh(isRefresh);
       //location.reload()
        history.push(`/profile/${slug}`)
        window.location.reload()
        //toggle()
    };
    console.log('Auth User =>>>',slug);
    console.log('Auth User =>>>',crdID);
    useEffect(() => {
        if(crdID){
            const fetchUserCard = async () => {
                try {
                    const response = await axios.get(`/api/usercard/${crdID}`);
                    const { data } = await response.data;
                    //setCard(data);
                    console.log("data ====>>>>>", data.profile.prfSlug);
                    history.push(`/profile/${data.profile.prfSlug}`)
//                    return <Redirect to={`/profile/${data.profile.prfSlug}`}/>
                } catch (error) {
                    console.log(error);
                }
            }
            fetchUserCard();
        }else{
            const fetchCard = async () => {
                try {
                    const response = await axios.get(`/api/cards/${slug}/slug`);
                    const { data } = await response.data;
                    setCard(data);
                    console.log("data ====>>>>>", data);
                } catch (error) {
                    console.log(error);
                }
            }
            fetchCard();
        }
        
    }, [slug,crdID]);

    console.log('Refresh ====>',isRefresh);
    const handleLogout = () => {
        logout()
        history.push(`/auth/login`)
    }
    
    return (
        <>
            {
                card
                    ?
                    <>
                        <Card className="card-user" style={{ width: "100%", maxWidth: "600px", marginInline: "auto", marginTop: "10px" }}>
                        
                            <div className="image" style={{postion:'relative'}}>
                                <img
                                    alt="..."
                                    src={require("assets/img/bg/damir-bosnjak.jpg").default}
                                />
                            </div>
                            <div style={{position:'absolute', right:0}}> 
                                {authUser &&     
                                <UncontrolledDropdown style={{marginLeft:'auto', zIndex:10}}>
                                    <DropdownToggle  color="default" nav>
                                    <i className="fa fa-ellipsis-v" style={{color:'khaki'}}/>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-danger" right>
                                        <DropdownItem onClick={toggle}>
                                            Change Photo
                                        </DropdownItem>
                                        <DropdownItem onClick={toggleName}>
                                            Change User Name
                                        </DropdownItem>
                                        <DropdownItem onClick={togglePassword}>
                                            Change Password
                                        </DropdownItem>
                                        <DropdownItem onClick={toggleEmail}>
                                            Change Email
                                        </DropdownItem>

                                        <DropdownItem onClick={toggleForm}>
                                            Change Basic Info
                                        </DropdownItem>
                                        <DropdownItem onClick={toggleSocial}>
                                            Change Social Medial
                                        </DropdownItem>
                                        <DropdownItem onClick={handleLogout}>
                                            Logout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </div>
                            <CardBody>
                                {console.log(card.prfImage)}
                                <div className="author">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="avatar border-gray"
                                            src={card.prfImage ? card.prfImage : require("assets/img/default-avatar.png").default}
                                            style={{padding:5, backgroundColor:'#FFF'}}
                                        />
                                        <h5 className="title">{card.prfFullname}</h5>
                                    </a>
                                    <p className="description"><i className="fa fa-envelope" /> {card.prfEmail} &nbsp; <i className="fa fa-phone" /> {card.prfName}</p>
                                </div>
                                <p className="description text-center">
                                    {card.prfQuote}
                                </p>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="button-container">
                                    <Row style={{paddingLeft: 30, paddingRight: 30}}>
                                        {card.prfLinkedin && 
                                        <Col className="ml-auto" >
                                        <a href={`https://www.linkedin.com/${card.prfLinkedin}`}>
                                            <img
                                                alt="..."
                                                src={require("assets/img/linkedin.png").default}
                                                style={{width: 60, height: 60}}
                                            />
                                        </a>
                                        </Col>
                                        }
                                        {card.prfFacebook &&   
                                        <Col className="ml-auto" >
                                        <a href={`https://www.facebook.com/${card.prfFacebook}`}>
                                            <img
                                                alt="..."
                                                src={require("assets/img/facebook.png").default}
                                                style={{width: 60, height: 60}}
                                            />
                                        </a>
                                        </Col>
                                        }
                                        {card.prfTwitter &&
                                        <Col className="ml-auto" >
                                        <a href={`https://www.twitter.com/${card.prfTwitter}`}> 
                                            <img
                                                alt="..."
                                                src={require("assets/img/twitter.png").default}
                                                style={{width: 60, height: 60}}
                                            />
                                        </a>
                                        </Col>
                                        }
                                        {card.prfInstagram &&
                                        <Col className="ml-auto">
                                        <a href={`https://www.instagram.com/${card.prfInstagram}`}>
                                            <img
                                                alt="..."
                                                src={require("assets/img/instagram.png").default}
                                                style={{width: 60, height: 60}}
                                            />
                                        </a>
                                        </Col>
                                    }
                                    </Row>
                                </div>
                            </CardFooter>
                        </Card>
                        
                           { authUser && <PhotoForm isOpen={isPhoto} onToggle={toggle} isRefresh={isRefresh} onRefresh={onRefresh} card={card}></PhotoForm> }
                           <ChangeName isOpen={isName} onToggle={toggleName} card={card}></ChangeName>
                           <ChangePassword isOpen={isPassword} onToggle={togglePassword} isRefresh={isRefresh} onRefresh={onRefresh} card={card} ></ChangePassword>
                           <ChangeEmail isOpen={isEmail} onToggle={toggleEmail} isRefresh={isRefresh} onRefresh={onRefresh} card={card}></ChangeEmail>
                           <ChangeSocial isOpen={isSocial} onToggle={toggleSocial} isRefresh={isRefresh} onRefresh={onRefresh} card={card}></ChangeSocial>
                           <ChangePage isOpen={isForm} onToggle={toggleForm} isRefresh={isRefresh} onRefresh={onRefresh} card={card}></ChangePage>
                    </>
                    : ''
            }

        </>
    )
}

export default Template01
