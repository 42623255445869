import axios from "axios";

import errorResponseHandler from "./errorResponseHandler";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API}`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const conf = config;
    if (localStorage.getItem("token")) {
      conf.headers.Authorization = `Token ${localStorage.getItem("token")}`;
    }
    return conf;
  },
  (error) => Promise.reject(error),
  errorResponseHandler
);

export default instance;

export const logout = () => {
  localStorage.removeItem('token');
}

export const isLogin = () => {
  if (localStorage.getItem('token')) {
      return true;
  }

  return false;
}