import React, { Suspense, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button, Card, CardBody, Collapse } from 'reactstrap'
import { useResetRecoilState } from 'recoil';
import { cardsSelector } from 'recoil/Card';
import CardForm from './CardForm';

const Toolbars = (props) => {
    const { panelState, closePanel, openPanel, action } = props
    const [ disableRefreshButton, setDisableRefreshButton ] = useState(false);
    const resetCards = useResetRecoilState(cardsSelector);

    const refreshCards = async () => {
        setDisableRefreshButton(true)
        await resetCards();
        setDisableRefreshButton(false)
    }

    return (
        <>
            <div className="float-right">
                <Button 
                    className="btn-icon mt-0 mb-3"
                    title="Refresh table data"
                    color="dark"
                    disabled={disableRefreshButton}
                    onClick={refreshCards}>
                    <i className="nc-icon nc-refresh-69" />
                </Button>
                <Button className="mt-0 mb-3" hidden={panelState} color="dark" onClick={() => openPanel('CREATE')}>Create new card</Button>
            </div>
            <div className="clearfix"></div>
            <Collapse isOpen={panelState}>
                <Card>
                    <CardBody className="px-md-5">
                        <Suspense fallback={<Skeleton height="50px" />}>
                            <CardForm closePanel={closePanel} action={action} />
                        </Suspense>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    )
}

export default Toolbars
