import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from '../utils/axios';

const index = () => {
    const { slug } = useParams();
    const [card, setCard] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const fetchCard = async () => {
            try {
                const response = await axios.get(`/api/cards/${slug}`);
                const { data } = await response.data;
                //window.location.href = data.crdURL;
                setCard(data);
            } catch (error) {
                setError(JSON.stringify(error))
                console.log(error.response);
            }
        }
        fetchCard();
    }, [slug]);

    return (
        <div>
            {card ? '' : ''}
        </div>
    );
};

export default index;
