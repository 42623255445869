import React, { useEffect,useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";

import axios from '../../utils/axios';
import { usercardsSelector } from "recoil/Card";
import { currentUserCardID } from "recoil/Card";
import { currentUserCardSelector } from "recoil/Card";
import { cardsSelector } from "recoil/Card";

import Select from 'react-select'



function CardForm(props) {
  const { closePanel, action } = props;
  const selectedCard = useRecoilValue(currentUserCardSelector);
  const isEditMode = selectedCard ? true : false;
  const resetCards = useResetRecoilState(usercardsSelector);
  const setCurrentCardID = useSetRecoilState(currentUserCardID);
  
  const customer = useRecoilValue(cardsSelector)

  const validationSchema = yup.object({
//    prfFullname: yup.string().required("Card Name is required"),
  })

  const initialValues = {
    crdID: '',
    usrID: ''
  };

  const options = [{value:"", label: ""}]
  customer.map((cust) => {
    console.log(">>>>>>>", cust)
    options.push({value: cust.prfID, label: `${cust.prfFullname}  -   ( ${cust.prfEmail} )`})
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      let data = {};
      if (values.usrID.value === undefined){
        data = {
          crdID: values.crdID,
          usrID: ''
        }
      }else{
        data = {
          crdID: values.crdID,
          usrID: values.usrID.value
        }
      }
      

//      data.prfSlug = data.prfFullname.split(' ').join('.')
      console.log("SAVE ====>>>>" ,data)
      //for (const [key, value] of Object.entries(data)) {
      //  formData.append(key, value)
     // }

      try {
        //const conf = { headers: { 'Content-Type': 'multipart/form-data' } }
        let message = '';
        if (isEditMode) {
          const res = await axios.put(`/api/usercard/${selectedCard.crdID}`, data);
          message = res.data.message;
        } else {
          const res = await axios.post('/api/usercard', data);
          message = res.data.message;
        }
        resetCards();
        handleCancel();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        console.log (error)
        //const response = error.error.response;
        //if (response.status !== 500) {
        //  const { data } = response.data;
        //  data.map((err) => formik.setFieldError(err.param, err.msg));
       // }
      }
    },
  });

  useEffect(() => {
    if (isEditMode) {
      const fields = ['crdID', 'usrID'];
      fields.map((field) => formik.setFieldValue(field, selectedCard[field], false))
    }
    

  }, [selectedCard])

  const handleCancel = () => {
    closePanel();
    formik.resetForm();
    setCurrentCardID(0);
  }


  return (
    <>
      <Row>
        <Col md="12 mt-3">
          <Form onSubmit={formik.handleSubmit} className="form-horizontal" method="post">
           
            <Row>
              <Label sm="2">Card Number</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="crdID"
                    readOnly={action === 'READ'}
                    value={formik.values.crdID}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.crdID && Boolean(formik.errors.crdID) && <label className="mt-0 mb-3 text-danger">{formik.errors.crdID}</label>}
              </Col>
            </Row>
            {console.log(options)}
            <Row>
              <Label sm="2">Customer</Label>
              <Col sm="10">
                <FormGroup>
               {/**  <Input
                  type="select"
                  placeholder="Choose Customer"
                  onChange={formik.handleChange}
                  name="usrID"
                  readOnly={action === 'READ'}
                  value={formik.values.usrID}
                >
                  <option value="0">Choose Customer</option>
                  {customer.map((cust) => 
                    <option value={cust.prfID}>{cust.prfFullname} ({cust.prfEmail})</option>  
                  )}
                  
                </Input>
                */}
                <Select
                  name="usrID"
                  //value={formik.values.usrID}
                  onChange={(value) => formik.setFieldValue('usrID', value)}
                  options={options}
                  onBlur={formik.handleBlur}
                   />
                </FormGroup>
                {formik.touched.usrID && Boolean(formik.errors.usrID) && <label className="mt-0 mb-3 text-danger">{formik.errors.usrID}</label>}
              </Col>
            </Row>
         
            <Row >
              <Label sm="2" />
              <Col sm="10" className="text-right">
                <FormGroup>
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    {action === 'READ' ? 'Close' : 'Cancel'}
                  </Button>
                  {action !== 'READ' &&
                    <Button
                      type="submit"
                      color="primary">
                      Save
                    </Button>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default CardForm;
