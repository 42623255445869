import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { AgGridReact } from 'ag-grid-react';

import axios from '../../utils/axios';
import { currentUserCardID, usercardsFilterParams, usercardsSelector } from '../../recoil/Card';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'assets/css/ag-grid.css';
import Swal from 'sweetalert2';
import { Row } from 'reactstrap';

const Table = (props) => {
  const { openPanel } = props;
  const [gridApi, setGridApi] = useState(null);
  const [queryParams, setQueryParams] = useRecoilState(usercardsFilterParams);
  const rowData = useRecoilValue(usercardsSelector);
  const setCurrentCardID = useSetRecoilState(currentUserCardID);
  const resetCards = useResetRecoilState(usercardsSelector);

  // grid options
  const gridOptions = {
    onFilterChanged: (params) => {
      const dataFilter = params.api.getFilterModel();
      setQueryParams({ ...queryParams, dataFilter });
    },

    onSortChanged: (params) => {
      const dataSort = params.api.getSortModel();
      setQueryParams({ ...queryParams, dataSort });
    },

    suppressCellSelection: true,
  };

  // saat grid ready, set data awal
  const onGridReady = (params) => {
    
    setGridApi(params.api);
  };

  // set data filter
  const setDataFilter = () => {

    const { dataFilter } = queryParams;
    console.log("....", dataFilter)
    Object.entries(dataFilter).forEach((e) => {
      const filterInstance = gridApi.getFilterInstance(e[0]);
      const model = {
        type: "contains",
        filter: dataFilter[e[0]].filter,
      };
      filterInstance.setModel(model);
    });
  };

  const actionButton = (cell) => {
    const { data } = cell;

    const handleEdit = () => {
      setCurrentCardID(data.crdID);
      openPanel('UPDATE');
    };

    const handleView = () => {
      setCurrentCardID(data.crdID);
      openPanel('READ');
    };

    const handleLockUnlock = () => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: data.crdStatus ? 'Yes, lock it!' : 'Yes, unlock it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.patch(`/api/usercard/${data.crdID}/${data.crdStatus ? 'lock' : 'unlock'}`);
          Swal.fire(
            'Success!',
            res.data.message,
            'success'
          );
          resetCards();
        }
      })
    };

    return (
      <>
        <i
          onClick={handleView}
          role="button"
          className="fa fa-eye"
        ></i>
        <i
          onClick={handleEdit}
          role="button"
          className="fa fa-edit ml-2"
        ></i>
        <i
          onClick={handleLockUnlock}
          role="button"
          className={`fa ${data.crdStatus ? 'fa-unlock' : 'fa-lock'} ml-2`}
        ></i>
      </>
    );
  };

  useEffect(() => {
    if (gridApi) {
      setDataFilter();
      
    }
  }, [gridApi]);

  const crdActiveFilter = forwardRef((_props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = "";
          } else {
            inputRef.current.value = parentModel.filter + "";
          }
        },
      };
    });

    const onSelectOptionChanged = (input) => {
      _props.parentFilterInstance((instance) => {
        const gridApi = instance.textFilterParams.api;
        const filterInstance = gridApi.getFilterInstance("crdStatus");
        const model = {
          type: "contains",
          filter: input.target.value,
        };
        filterInstance.setModel(model);
        gridApi.onFilterChanged();
      });
    };

    return (
      <>
        <select
          className="select-box-floating-filter"
          ref={inputRef}
          onInput={onSelectOptionChanged}
        >
          <option value="1">Active</option>
          <option value="0">Not Active</option>
          <option value="">All</option>
        </select>
      </>
    );
  });

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    floatingFilter: true,
    suppressMenu: true,
    filterParams: { apply: false, newRowsAction: "keep", buttons: ["apply"] },
  };

  const columnDefs = [
    { headerName: "Card Number", field: "crdID" },
    { headerName: "Name", field: "prfFullname" },
    { headerName: "Email", field: "prfEmail" },
    {
      headerName: "Status",
      field: "crdStatus",
      floatingFilterComponent: "crdActiveFilter",
      cellRenderer: (params) => params.data.crdStatusLabel,
    },
    { headerName: "Action", cellRenderer: "actionButton", filter: false },
  ];

  console.log("params.. ", rowData)
  return (
    <div className="ag-theme-alpine" style={{ height: 570 }}>
      {/* AG Grid */}
      <AgGridReact
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        rowData={rowData}
        frameworkComponents={{ actionButton, crdActiveFilter }}
        rowSelection="single"
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default Table;
