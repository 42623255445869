import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-loading-skeleton/dist/skeleton.css'
import views from 'views';
import Template01 from 'views/Template01';
import Template02 from 'views/Template02';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <RecoilRoot>
        <Suspense fallback="">
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <PrivateRoute exact path="/:slug" component={views} />
          <PublicRoute exact path="/profile/:slug" component={Template01} />
          <PublicRoute exact path="/profile/:crdID" component={Template01} />
          <PrivateRoute exact path="/profile-edit/:slug" component={Template01} />
          <PrivateRoute exact path="/profile/:slug/admin" component={Template02} />
        </Suspense>
      </RecoilRoot>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
