import Recoil, { atom, selector } from "recoil";
import axios from '../utils/axios';

export const currentCardID = atom({
    key: "currentCardID",
    default: 0
});

export const currentUserCardID = atom({
    key: "currentUserCardID",
    default: 0
});

export const currentCardSelector = selector({
    key: "currentCardSelector",
    get: async ({ get }) => {
        const prfID = get(currentCardID);
        if (prfID) {
            try {
                const response = await axios.get(`/api/cards/${prfID}`);
                const { data } = await response.data;
                return data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const currentUserCardSelector = selector({
    key: "currentUserCardSelector",
    get: async ({ get }) => {
        const crdID = get(currentUserCardID);
        if (crdID) {
            try {
                const response = await axios.get(`/api/usercard/${crdID}`);
                const { data } = await response.data;
                return data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const cardTrigger = atom({
    key: "cardTrigger",
    default: 0
});

export const cardsFilterParams = atom({
    key: 'cardsFilterParams',
    default: {
        limit: 100,
        dataFilter: { prfActive: { filter: 1 } },
        dataSort: [],
    }
})


export const usercardsFilterParams = atom({
    key: 'usercardsFilterParams',
    default: {
        limit: 100,
        dataFilter: { crdStatus: { filter: 1 } },
        dataSort: [],
    }
})

export const cardsSelector = selector({
    key: "cardsSelector",
    get: async ({ get }) => {
        get(cardTrigger);
        try {
            const strParams = JSON.stringify(get(cardsFilterParams));
            const response = await axios.get(`/api/cards?params=${strParams}`);
            const { data } = await response.data;
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(cardTrigger, v => v + 1);
        }
    }
});

export const usercardsSelector = selector({
    key: "usercardsSelector",
    get: async ({ get }) => {
        get(cardTrigger);
        try {
            const strParams = JSON.stringify(get(usercardsFilterParams));
            const response = await axios.get(`/api/usercard?params=${strParams}`);
            const { data } = await response.data;
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(cardTrigger, v => v + 1);
        }
    }
});
