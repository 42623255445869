import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import axios from '../utils/axios';
import BackgroundProfile from '../assets/img/bg/damir-bosnjak.jpg'
import PhotoForm from "./Profiles/PhotoForm";
import {
    Card,
    CardBody,
    CardFooter,
    Row,
    Col,
    CardHeader,
    CardTitle,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
    Modal
} from "reactstrap";
// core components
import {
    chartExample10,
} from "variables/charts.js";

const Template02 = () => {
    //const [visiblePhotoForm, setVisiblePhotoForm] = useState(false);
    const { slug } = useParams();
    const [card, setCard] = useState();
    const [isPhoto, setPhoto] = useState(false);
    useEffect(() => {
        const fetchCard = async () => {
            try {
                const response = await axios.get(`/api/cards/${slug}/slug`);
                const { data } = await response.data;
                setCard(data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchCard();
    }, [slug]);


    console.log("data ====>>>>>", card);
    const handlePhoto = () =>{
        setPhoto(true)
    }
    return (
        <>
            {
                card
                    ?
                    <>
                        <Card className="card-user" style={{ width: "100%", maxWidth: "600px", marginInline: "auto", marginTop: "10px" }}>
                            <div className="image" style={{backgroundImage: `url(${BackgroundProfile})` , borderTopLeftRadius:15, borderTopRightRadius:15, display:'flex'}}>
                                
                            <UncontrolledDropdown style={{marginLeft:'auto'}}>
                                <DropdownToggle  color="default" nav>
                                <i className="fa fa-ellipsis-v" style={{color:'khaki'}}/>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-danger" right>
                                <DropdownItem to="/sections#headers" tag={Link}>
                                    Change Photo
                                </DropdownItem>
                                <DropdownItem to="/sections#headers" tag={Link}>
                                    Change User Name
                                </DropdownItem>
                                <DropdownItem to="/sections#headers" tag={Link}>
                                    Change Password
                                </DropdownItem>
                                <DropdownItem to="/sections#headers" tag={Link}>
                                    Change Email
                                </DropdownItem>

                                <DropdownItem to="/sections#headers" tag={Link}>
                                    Edit Page
                                </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            </div>
                            
                            <CardBody>
                                
                                <div className="author">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <div style={{position:'relative'}}>
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                src={card.prfImage ? card.prfImage : require("assets/img/default-avatar.png").default}

                                            />
                                            <i className="fa fa-edit" 
                                                style={{position:'absolute', bottom:20, right:'40%', fontSize:16, color:'skyblue'}}
                                                onClick={handlePhoto}
                                                />
                                        </div>
                                        <div style={{position:'relative'}}>
                                            <span className="title" style={{position:'relative', fontSize:20}}>{card.prfFullname}</span> 
                                            <i className="fa fa-edit" style={{position:'absolute', bottom:5, right:0, fontSize:16, color:'skyblue'}}/>
                                        </div>
                                    </a>
                                    <p className="description" style={{marginTop:10}}>
                                        <div style={{position:'relative'}}>
                                            <i className="fa fa-envelope" /> {card.prfEmail} 
                                            <i className="fa fa-edit" style={{position:'absolute', bottom:5, right:0, fontSize:16, color:'skyblue'}}/>
                                        </div>
                                        <div style={{position:'relative'}}>
                                            <i className="fa fa-phone" /> {card.prfName}
                                            <i className="fa fa-edit" style={{position:'absolute', bottom:5, right:0, fontSize:16, color:'skyblue'}}/>
                                        </div>
                                    </p>
                                </div>
                                <p className="description text-center">
                                    "I like the way you work it <br />
                                    No diggity <br />I wanna bag it up"
                                </p>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="button-container" style={{position:'relative'}}>
                                    <Row>
                                        <Col className="ml-auto" lg="3" md="6" xs="6">
                                        <img
                                                alt="..."
                                                className="avatar border-gray"
                                                src={card.prfImage ? card.prfImage : require("assets/img/default-avatar.png").default}
                                                style={{width:50, height:50}}
                                            />
                                        </Col>
                                        <Col className="ml-auto mr-auto" lg="4" md="6" xs="6">
                                        <img
                                                alt="..."
                                                className="avatar border-gray"
                                                src={card.prfImage ? card.prfImage : require("assets/img/default-avatar.png").default}
                                                style={{width:50, height:50}}
                                            />
                                        </Col>
                                        <Col className="mr-auto" lg="3">
                                        <img
                                                alt="..."
                                                className="avatar border-gray"
                                                src={card.prfImage ? card.prfImage : require("assets/img/default-avatar.png").default}
                                                style={{width:50, height:50}}
                                            />
                                        </Col>
                                        <i className="fa fa-edit" style={{position:'absolute', bottom:5, right:0, fontSize:16, color:'skyblue'}}/>
                                    </Row>
                                </div>
                                
                            </CardFooter>
                        </Card>
                        <Card className="card-chart" style={{ maxWidth: "600px", marginInline: "auto" }}>
                            <CardHeader>
                                <CardTitle>Views</CardTitle>
                                <p className="card-category">Bar Chart</p>
                            </CardHeader>
                            <CardBody>
                                <Bar
                                    data={chartExample10.data}
                                    options={chartExample10.options}
                                />
                            </CardBody>
                        </Card>
                        <Modal isOpen={isPhoto}>
                            <PhotoForm></PhotoForm>
                        </Modal>
                    </>
                    : 'loading...'
            }

        </>
    )
}

export default Template02
